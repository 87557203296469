<template>
  <div class="rule-go-chat">
    <div class="title">
      <span>CHAT</span>
      <ButtonClose @click="$emit('close')" />
    </div>
    <el-scrollbar ref="elScrollbarRef" class="content">
      <template v-for="(message, index) of messageList">
        <RenderUserChat v-if="message.isUser" :key="index + 'user'" :value="message.content" />
        <RenderAiChat @apply="onApply" v-else :key="index + 'ai'" :value="message" />
      </template>
      <ThreeDotLoading v-if="!Boolean(enableInput)" />
    </el-scrollbar>
    <ChatInput v-model="inputValue" @send="onSubmit" placeholder="Ask Cereb Assistant" />
  </div>
</template>

<script>
import { v4 as uuidV4 } from 'uuid';
import { newConversation, sendMessage, getMessage, webSocketClient } from '@/api';
import ButtonClose from './ButtonClose.vue';
import RenderUserChat from './RenderUserChat.vue';
import RenderAiChat from './RenderAiChat.vue';
import ChatInput from './ChatInput.vue';
import ThreeDotLoading from './ThreeDotLoading.vue';

export default {
  components: {
    ButtonClose,
    RenderUserChat,
    RenderAiChat,
    ChatInput,
    ThreeDotLoading
  },
  emits: ['close', 'apply'],
  props: {
    value: {
      type: String,
      default: ''
    },
    chainId: {
      type: [String, Object],
      default: null
    },
  },
  data() {
    return {
      // 输入框文本
      inputValue: '',
      // 对话信息
      messageList: [],
      // 对话 ID
      // conversationId: '',
      // 是否正在加载
      loading: false,
      // 定时器
      timer: null,
      // 接收消息定时器
      receiveMessageTimer: null,
      // 是否禁止输入
      enableInput: true,
      isWorkflow: false,
      stompClient: null,
      conversation: '',
      scrollTimer: null,
    };
  },
  computed: {
    conversationId() {
      return this.conversation && this.conversation.conversationId;
    },
    websocketId() {
      return this.conversation && this.conversation.websocketId;
    },
  },
  watch: {
    show(val) {
      const me = this;
      if (val) {
        me.$nextTick(() => {
          // 自动聚焦至输入框
          me.$refs.inputRef.focus();
          window.messageListRef = me.$refs.messageListRef;
          document.addEventListener("keydown", me.handleESC);
        })

      } else {
        document.removeEventListener("keydown", me.handleESC);
      }
    },
    async messageList() {
      const me = this;
      me.scrollToEnd();
    }
  },
  methods: {
    scrollToEnd() {
      const me = this;
      if (me.scrollTimer) {
        clearTimeout(me.scrollTimer);
      }
      me.scrollTimer = setTimeout(() => {
        const el = me.$refs.elScrollbarRef.$el.querySelector('.el-scrollbar__wrap');
        const elScrollbarView = el.firstChild;
        const lastChild = elScrollbarView.childNodes[elScrollbarView.childNodes.length - 2];
        if (lastChild) {
          lastChild.scrollIntoView();
        }
      }, 500);
    },
    onApply(value) {
      this.$emit('apply', value);
    },
    saveMessageList() {
      this.$store.dispatch('rulegoChat/saveMessages', this.messageList);
    },
    restoreMessageList() {
      this.messageList = this.$store.state.rulegoChat.messages;
    },
    onClickModal(event) {
      const me = this;
      if (event.target.classList.contains('dialog-overlay')) {
        me.onClose();
      }
    },
    /**
     * 清除计时器
     */
    clearTimer() {
      const me = this;
      clearInterval(me.timer);
      me.timer = null;
    },
    /**
     * 锁定输入
     */
    lockInput() {
      this.enableInput = false;
    },
    /**
     * 解锁输入
     */
    unlockInput() {
      const me = this;
      // 当出错或未拉取到回复消息时
      // 删除当前加载态消息
      if (!me.messageList.length &&
        !me.messageList[me.messageList.length - 1].isUser &&
        me.messageList[me.messageList.length - 1].content === '') {
        me.messageList[me.messageList.length - 2].hasError = true;
        me.messageList.pop();
      }
      me.enableInput = true;
    },
    async initConversation() {
      const me = this;
      try {
        const res = await newConversation(['rulego-master-02'], { 'chain_id': me.chainId });
        me.conversation = res.conversation;
      } catch (error) {
        me.$message.error('Error');
      }
    },
    scrollListToEnd() {
      this.$nextTick(() => {
      })
    },
    async handleSendMessage(message) {
      const me = this;
      const { userMessage } = me.addMessage(message);
      await me.createStompClient();
      const sendMessageResponse = await sendMessage(me.conversationId, { content: message });
      userMessage.sequenceNumber = sendMessageResponse.message.sequenceNumber;
      me.remedyMessage(message, userMessage.sequenceNumber);
    },
    async createStompClient() {
      const me = this;
      if (me.stompClient) {
        return Promise.resolve(me.stompClient)
      }
      // promise
      let handleResolve;
      let handleReject;
      const promise = new Promise((resolve, reject) => {
        handleResolve = resolve
        handleReject = reject
      })
      const stompClient = webSocketClient('/ws/shadow_websocket')
      const destination = '/user/topic/shadow_websocket'
      const headers = {
        'cereb-websocket-id': me.websocketId,
        'cereb-websocket-path': `/${me.conversationId}`,
        "access-token": localStorage.token
      }

      stompClient.connect(headers, () => {
        handleResolve(stompClient)
        stompClient.subscribe(destination, function (response) {
          const websocketDataVo = JSON.parse(response.body);
          const messageObject = JSON.parse(websocketDataVo.content)
          me.handleMessage(messageObject)
        })
      }, () => {
        handleReject()
      });
      me.stompClient = stompClient;
      return promise;
    },
    delay(time) {
      return new Promise((resolve) => {
        setTimeout(resolve, time);
      })
    },
    async handleFunctionEnd(data) {
      console.log('handleFunctionEnd', data)
    },
    /**
    * 处理消息
    * @param data 消息数据服务端
    * @param currentMessageItem 当前消息客户端
    */
    handleMessage(data) {
      const me = this;
      window.clearTimeout(me.receiveMessageTimer)
      console.log('handleMessage', data)
      if (typeof data?.event == 'object') {
        switch (data.event.type) {
          case 'function_start':
            if (data.event.functionName === 'start_workflow') {
              me.lockInput()
              me.isWorkflow = true
            }
            break
          case 'function_end':
            if (data.event.functionName === 'start_workflow') {
              me.isWorkflow = false
            }
            else {
              me.handleFunctionEnd(data)
            }
            break
          case 'reply-end':
            me.unlockInput()
            break
        }
      } else if (data?.isStreaming === true || data?.isCompleted === true) {
        me.handleStreamingMessage(data)
      } else {
        me.handleCoreMessage(data)
      }
      me.scrollListToEnd()
    },
    getLastMessageItem() {
      const me = this;
      return me.messageList[me.messageList.length - 1]
    },
    updateLastMessage(message) {
      const lastItem = this.getLastMessageItem()
      Object.assign(lastItem, message)
    },
    /*处理流消息*/
    handleStreamingMessage(data) {
      const me = this;
      console.log('handleStreamingMessage', data)
      const item = me.getLastMessageItem()
      item.needsAnimation = false
      let content = item.content
      if (data.isCompleted === true) {
        //流结束直接覆盖
        content = data.message.content
      } else {
        //逐个token追加
        content += data.message.content
      }

      me.updateLastMessage({ ...data, content })
    },
    /*添加消息*/
    addMessage(content) {
      const me = this;
      me.lockInput()
      // 添加一条用户消息 和 bot 加载消息
      const userMessage = { content, createdTime: new Date(), isUser: true, id: uuidV4(), type: 'text' };
      const botMessage = { content: '', createdTime: new Date(), isUser: false, id: uuidV4(), type: 'text' };
      me.messageList.push(...[userMessage, botMessage]);
      me.scrollListToEnd()
      return { userMessage, botMessage }
    },
    /*核心消息处理*/
    handleCoreMessage(data) {
      const me = this;
      console.log('handleCoreMessage', data)
      switch (data?.message?.type) {
        case 'text':
          {
            if (data?.source === 'Workflow') {
              me.addMessage(data.message.content)
            } else {
              me.updateLastMessage(data.message)
            }
            if (!me.isWorkflow) {
              me.unlockInput()
            }
          }
          break
        case 'hidden':
          //do nothing
          break
        default:
          me.updateLastMessage(data.message);
          break;
      }
    },
    remedyMessage(inputValue, sequenceNumber) {
      const me = this;
      me.receiveMessageTimer = setTimeout(async () => {
        try {
          let totalTime = 0;
          const interval = 500;// 0.5s间隔
          me.timer = setInterval(async () => {
            totalTime += interval;
            if (totalTime > 30 * 1000) {
              me.clearTimer();
              me.unlockInput();
            }

            const getMessageResponse = await getMessage(me.conversationId, sequenceNumber);
            const { messages } = getMessageResponse;
            if (messages.length > 1) {
              for (let i = 1; i < messages.length; i++) {
                const message = messages[i];
                if (message.sequenceNumber === sequenceNumber) {
                  continue;
                }
                me.handleMessage(message);
              }
              me.clearTimer();
            }
          }, interval);

        } catch (error) {
          me.$message.error('Error');
          me.clearTimer();
          me.unlockInput();
        }
      }, 5 * 1000)
    },
    async onSubmit() {
      const me = this;
      if (!me.enableInput) return;
      if (!me.inputValue) return;

      me.scrollListToEnd();

      const tempInput = me.inputValue;
      // 置空
      me.inputValue = '';

      // 尝试一次初始化, 失败则忽略此次发送消息
      if (!me.conversation) {
        await me.initConversation();
      }

      await me.handleSendMessage(tempInput);
    },
    handleESC(event) {
      const me = this;
      if (event.code === "Escape") {
        console.log('Escape');
        me.onClose();
      }
    },
  },
  mounted() {
    const me = this;
    me.restoreMessageList();
  },
  beforeDestroy() {
    const me = this;
    me.saveMessageList();
    me.clearTimer();
    me.receiveMessageTimer && clearTimeout(me.receiveMessageTimer);
    me.stompClient && me.stompClient.disconnect();
  },
}
</script>
<style>
.rule-go-chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: sans-serif;
  color: white;
  background-color: #181818;
}

.rule-go-chat .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 5px 10px;
  margin-bottom: 10px;
  cursor: move;
}

.rule-go-chat .title>span {
  border-bottom: 1px solid white;
}

.rule-go-chat .content {
  flex: 1 1 0;
}

.rule-go-chat .button {
  display: inline-block;
  border: none;
  border-left: 1px solid #323232;
  border-top: 0;
  height: 100%;
  color: #919196;
  padding: 0 10px;
  background-color: transparent;
}

.rule-go-chat .button i {
  margin-right: 5px;
}
</style>

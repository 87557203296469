<template>
    <div class="back-button">
        <el-button @click="goBack">Back</el-button>
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>

<style>
.back-button {
    display: block;
}
</style>

export function assignCoordinates(ruleJSON) {
  let data;
  try {
    data = JSON.parse(ruleJSON);
  } catch (err) {
    throw new Error(`Error parsing JSON: ${err}`);
  }

  const metadata = data.metadata;
  if (!metadata) {
    throw new Error("Invalid metadata format");
  }

  const graph = {};
  const inDegree = {};
  const nodes = {};

  // Ensure ruleChain has additionalInfo
  const ruleChain = data.ruleChain;
  if (!ruleChain.additionalInfo) {
    ruleChain.additionalInfo = { layoutX: 0, layoutY: 0 };
  }

  const endpoints = metadata.endpoints || [];

  endpoints.forEach((ep) => {
    const id = ep.id;
    // Ensure each endpoint has additionalInfo
    const additionalInfo = ep.additionalInfo || { layoutX: 0, layoutY: 0 };
    ep.additionalInfo = additionalInfo;
    graph[id] = [];
    nodes[id] = {
      layoutX: additionalInfo.layoutX,
      layoutY: additionalInfo.layoutY,
    };
    const routers = ep.routers;
    routers.forEach((r) => {
      const toPath = r.to.path;
      const pathParts = toPath.split(":");
      const toID = pathParts[pathParts.length - 1];
      graph[id].push(toID);
      inDegree[toID] = (inDegree[toID] || 0) + 1;
      if (!inDegree[id]) {
        inDegree[id] = 0;
      }
    });
  });

  const nodesData = metadata.nodes;
  if (!Array.isArray(nodesData)) {
    throw new Error("Invalid nodes format");
  }

  // Assign coordinates using a simple grid layout
  const gridSize = 400; // Adjust the grid size as needed
  let x = 0;
  let y = 0;
  const maxColumns = 5; // Adjust the number of columns as needed

  if (
    ruleChain.additionalInfo.layoutX === 0 &&
    ruleChain.additionalInfo.layoutY === 0
  ) {
    ruleChain.additionalInfo.layoutX = x * gridSize;
    ruleChain.additionalInfo.layoutY = y * gridSize;
    x++;
    if (x >= maxColumns) {
      x = 0;
      y++;
    }
  }

  // Assign coordinates to endpoints
  endpoints.forEach((ep) => {
    if (ep.additionalInfo.layoutX === 0 && ep.additionalInfo.layoutY === 0) {
      ep.additionalInfo.layoutX = x * gridSize;
      ep.additionalInfo.layoutY = y * gridSize;
      x++;
      if (x >= maxColumns) {
        x = 0;
        y++;
      }
    }
  });

  // Assign coordinates to nodes if not already set
  nodesData.forEach((node) => {
    if (!nodes[node.id]) {
      nodes[node.id] = { layoutX: 0, layoutY: 0 };
    }
    if (nodes[node.id].layoutX === 0 && nodes[node.id].layoutY === 0) {
      nodes[node.id].layoutX = x * gridSize;
      nodes[node.id].layoutY = y * gridSize;

      node.additionalInfo = node.additionalInfo || {};
      node.additionalInfo.layoutX = nodes[node.id].layoutX;
      node.additionalInfo.layoutY = nodes[node.id].layoutY;

      x++;
      if (x >= maxColumns) {
        x = 0;
        y++;
      }
    }
  });

  return JSON.stringify(data, null, 2);
}

<template>
  <div class="render-markdown-message" ref="renderedMarkdownContainer"></div>
</template>

<script>
import Vue from 'vue';
import MarkdownIt from 'markdown-it';
import MarkdownItAnchor from 'markdown-it-anchor';
import MarkdownItPrompt from '@/utils/MarkdownItPrompt';
import RenderCode from './RenderCode.vue';

const md = new MarkdownIt();
md.use(MarkdownItAnchor);
md.use(MarkdownItPrompt);

export default {
  emits: ['apply','rendered'],
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      md: md // Initialize markdown-it
    };
  },
  computed: {
    renderedMarkdown() {
      return this.md.render(this.value); // Render the markdown content
    }
  },
  watch: {
    renderedMarkdown(newVal) {
      this.$nextTick(() => {
        this.renderMarkdownToHtml(newVal);
      });
    }
  },
  mounted() {
    this.renderMarkdownToHtml(this.renderedMarkdown);
  },
  methods: {
    onApplyJson(value) {
      this.$emit('apply', value);
    },
    renderMarkdownToHtml(markdown) {
      const me = this;
      const container = this.$refs.renderedMarkdownContainer;
      container.innerHTML = markdown;

      const codeBlocks = container.querySelectorAll('pre code');
      codeBlocks.forEach(block => {
        if (block.classList.contains('language-json')) {
          const content = block.textContent.trim();
          const RenderCodeComponent = Vue.extend(RenderCode);
          const instance = new RenderCodeComponent({
            propsData: { value: content }
          });
          instance.$on('apply', value => {
            me.onApplyJson(value);
          });
          instance.$on('rendered', () => {
            me.$emit('rendered');
          });
          instance.$mount();
          block.parentNode.replaceWith(instance.$el); // Replace the entire pre element
        }
      });
    }
  }
};
</script>

<style scoped>
.render-markdown-message {
  padding: 5px 10px;
}
</style>
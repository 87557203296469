<template>
  <div class="render-rule-go-json-message">
    <p v-if="value.text !== ''">{{ value.text }}</p>
    <RenderCode v-if="getJsonString !== ''" :value="getJsonString" @apply="onApply" />
  </div>
</template>

<script>
import RenderCode from './RenderCode.vue';
export default {
  emits: ['apply'],
  components: {
    RenderCode
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
    }
  },
  computed: {
    getJsonString() {
      if (this.value.rulegoJson === undefined || this.value.rulegoJson === null) {
        return '';
      }
      else if (typeof this.value.rulegoJson === 'string') {
        return this.value.rulegoJson;
      }
      else {
        return JSON.stringify(this.value.rulegoJson, null, 2);
      }
    }
  },
  methods: {
    onApply(value) {
      this.$emit('apply', value);
    }
  },
  mounted() {
    console.log(this.value)
  }
}
</script>
<style>
.render-rule-go-json-message {
  padding: 5px 10px;
}
</style>

<template>
    <button @click="$emit('click')" class="button-close">
        <i class="el-icon-close"></i>
    </button>
</template>

<script>
export default {
    emits: ['click'],
}
</script>
<style>
.button-close {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    color: #D1D1D1;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
}

.button-close:hover {
    background-color: #323232;
}

.button-close:active {
    background-color: #454545;
}
</style>

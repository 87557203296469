<template>
  <div class="three-dot-loading">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    updateValue(value) {
      this.emit('input', value)
    }
  }
}
</script>
<style>
.three-dot-loading {
  flex: 1;
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  padding: 5px 10px;
  gap:5px;
}

.three-dot-loading span {
    width: 3px;
    height: 3px;
    display: inline-block;
    border-radius: 50%;
    background-color: white;
    animation: three_dot_loading 1.5s infinite ease-in-out both;
}

/* .loading span:nth-child(1) {} */

.three-dot-loading span:nth-child(2) {
    animation-delay: 0.2s;
}

.three-dot-loading span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes three_dot_loading {
    0% {
        opacity: .2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: .2;
    }
}
</style>

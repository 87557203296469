<template>
    <div class="render-user-chat">
        {{ value }}
    </div>
</template>

<script>
export default {
    emits: ['input'],
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
        }
    },
    methods: {
        updateValue(value) {
            this.emit('input', value)
        }
    }
}
</script>
<style>
.render-user-chat {
    background-color: #212121;
    border-top: 1px solid #3C3C3C;
    border-bottom: 1px solid #3C3C3C;
    padding: 5px 10px;
}
</style>

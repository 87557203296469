<template>
  <div class="demo-collapse" :style="properties.style">
    <div>当前值：{{ text }}</div>
    <div class="demo-operator">
      <el-input @keydown.stop type="text" v-model="increaseFactor" />
      <el-button @mousedown.stop @click="handleClick">增加</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    properties: {
      type: Object,
      default: () => ({
        t: 1,
        style: {}
      })
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      increaseFactor: 0
    };
  },
  watch: {
    'properties.t': {
      handler(newVal) {
        this.increaseFactor = newVal;
      },
      immediate: true
    }
  },
  methods: {
    handleClick() {
      this.$emit('btnClick', this.increaseFactor);
    }
  }
};
</script>
<style scoped>
.demo-collapse {
  width: 300px;
  height: 100px;
  border: 1px solid #444;
  box-sizing: border-box;
}
.demo-operator {
  display: flex;
}
</style>

<template>
  <div class="split-panel" :class="{ resizing: isResizing }" :style="computedStyle">
    <div class="content">
      <slot :width="panelWidth"></slot>
    </div>
    <template v-if="showHandler">
      <DividerVertical />
      <div class="handler" @mousedown.stop.prevent="startResize"></div>
    </template>
  </div>
</template>
<script>
import DividerVertical from './DividerVertical.vue';

export default {
  name: 'SplitPanel',
  components: {
    DividerVertical
  },
  props: {
    width: {
      type: Number,
      default: 400
    },
    minWidth: {
      type: Number,
      default: 300
    },
    showHandler: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      splitPanelGroup: null,
      panelWidth: this.width,
      isResizing: false,
      startX: 0,
      startWidth: 0,
    };
  },
  watch: {
    width(value) {
      this.panelWidth = value;
    }
  },
  computed: {
    computedStyle() {
      return {
        width: `${this.panelWidth}px`
      };
    }
  },
  methods: {
    findParent(name) {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options.name === name) {
          return parent;
        }
        parent = parent.$parent;
        if (!parent) {
          break;
        }
      }
      return null;
    },
    register() {
      this.splitPanelGroup.registerChild(this);
    },
    unregister() {
      this.splitPanelGroup.unregisterChild(this);
    },
    startResize(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isResizing = true;
      this.startX = event.clientX;
      this.startWidth = this.panelWidth;
      document.addEventListener('mousemove', this.onResize);
      document.addEventListener('mouseup', this.stopResize);
    },
    onResize(event) {
      if (this.isResizing) {
        const newWidth = this.startWidth + (event.clientX - this.startX);
        if (newWidth >= this.minWidth) {
          this.panelWidth = newWidth;
          this.$emit('update:width', this.panelWidth);
        }
      }
    },
    stopResize() {
      this.isResizing = false;
      document.removeEventListener('mousemove', this.onResize);
      document.removeEventListener('mouseup', this.stopResize);
    },
    setWidth(width) {
      if (width >= this.minWidth) {
        this.panelWidth = width;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.splitPanelGroup = this.findParent('SplitPanelGroup');
      this.register();
    });
  },
  beforeDestroy() {
    this.unregister();
  }
};
</script>
<style scoped>
.split-panel {
  display: flex;
  align-items: stretch;
  position: relative;
}

.split-panel.resizing {
  cursor: col-resize;
}

.split-panel>.content {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.split-panel>.content>* {
  flex: 1;
  overflow: hidden;
}

.split-panel>.handler {
  width: 5px;
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.split-panel .handler:hover {
  background-color: #0078d4;
}
</style>
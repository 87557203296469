import Vue from 'vue'
import { HtmlNode, HtmlNodeModel } from '@logicflow/core'
import VueNode from './VueNode.vue'

class VueHtmlNode extends HtmlNode {
  constructor(props) {
    super(props)
    this.isMounted = false
    this.r = new Vue({
      render: h => h(VueNode, {
        props: {
          properties: props.model.getProperties(),
          text: props.model.inputData
        },
        on: {
          btnClick: (i) => {
            this.r.$children[0].text = String(Number(this.r.$children[0].text) + Number(i))
          }
        }
      })
    })
  }

  setHtml(rootEl) {
    if (!this.isMounted) {
      this.isMounted = true
      const node = document.createElement('div')
      rootEl.appendChild(node)
      this.r.$mount(node)
    } else {
      this.r.$children[0].properties = this.props.model.getProperties()
    }
  }

  getText() {
    return null
  }
}

class VueHtmlNodeModel extends HtmlNodeModel {
  setAttributes() {
    this.width = 300
    this.height = 100
    this.text.editable = false
    this.inputData = this.text.value
  }

  getOutlineStyle() {
    const style = super.getOutlineStyle()
    style.stroke = 'none'
    style.hover.stroke = 'none'
    return style
  }

  getDefaultAnchor() {
    return []
  }

  getData() {
    const data = super.getData()
    data.text.value = this.inputData
    return data
  }
}

export default {
  type: 'vue-html',
  model: VueHtmlNodeModel,
  view: VueHtmlNode
}

<template>
  <button @click="$emit('click')" class="button-left-panel">
    <i class="el-icon-edit-outline icon"></i>
  </button>
</template>

<script>
export default {
  emits: ['click'],
}
</script>
<style>
.button-left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  background-color: #181818;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: none;
}

.button-left-panel:hover {
  background-color: #282828;
}

.button-left-panel:active {
  background-color: #454545;
}

.button-left-panel  .icon {
  color: #D1D1D1;
  font-size: 24px;
}
</style>

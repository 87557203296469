export default function MarkdownItPrompt(md ) {
  // Handle block-level <prompt> tags
  md.renderer.rules.html_block = (tokens, idx, options, env, self) => {
    const content = tokens[idx].content
    if (content.startsWith('<prompt>') && content.endsWith('</prompt>')) {
      const innerContent = content.slice(8, -9).trim()
      return `<div class="prompt">${md.render(innerContent)}</div>`
    }
    return self.renderToken(tokens, idx, options)
  }

  // Handle inline <prompt> tags
  md.renderer.rules.html_inline = (tokens, idx, options, env, self) => {
    const content = tokens[idx].content
    if (content.startsWith('<prompt>') && content.endsWith('</prompt>')) {
      const innerContent = content.slice(8, -9).trim()
      return `<span class="prompt">${md.renderInline(innerContent)}</span>`
    }
    return self.renderToken(tokens, idx, options)
  }
}

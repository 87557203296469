<template>
  <div class="render-code">
    <div class="render-code-container">
      <div class="render-code-header">
        <span class="render-code-title">
          <span class="render-code-icon">&lt;&#47;&gt;</span>&nbsp;json
        </span>
        <span class="render-code-buttons">
          <button class="button" @click="onCopy"><i class="el-icon-document-copy"></i>Copy</button>
          <button class="button" @click="onApply"><i class="el-icon-caret-right"></i>Apply</button>
        </span>
      </div>
      <codemirror ref="codeMirrorEditor" v-model="contentText" :options="codeMirrorOptions" />
    </div>
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css'; // 选择一个主题
import 'codemirror/mode/javascript/javascript.js'; // 导入 JSON 模式
import copy from 'copy-to-clipboard';

export default {
  components: {
    codemirror
  },
  emits: ['apply', 'rendered'],
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      contentText: '',
      codeMirrorOptions: {
        mode: 'application/json',
        theme: 'material', // 选择一个主题
        lineNumbers: true, // 显示行号
        tabSize: 2, // 设置 Tab 大小
        indentWithTabs: true, // 使用空格进行缩进
        autoCloseBrackets: true, // 自动闭合括号
        matchBrackets: true, // 匹配括号
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"], // 显示行号和折叠
        foldGutter: true, // 启用代码折叠
        readOnly: true,
      },
    };
  },
  methods: {
    onCopy() {
      copy(this.contentText);
      this.$message({
        message: 'Copied to clipboard!',
        type: 'success'
      });
    },
    onApply() {
      this.$emit('apply', this.contentText);
    },
    onRenderEnd() {
      this.$emit('rendered');
    }
  },
  watch: {
    value(newValue) {
      this.contentText = newValue;
    }
  },
  mounted() {
    this.contentText = this.value;
    this.$nextTick(() => {
      const codeMirrorInstance = this.$refs.codeMirrorEditor.codemirror;
      if (codeMirrorInstance) {
        codeMirrorInstance.on('update', this.onRenderEnd);
        // Example of accessing other properties and methods
        console.log('CodeMirror instance:', codeMirrorInstance);
        console.log('Current content:', codeMirrorInstance.getValue());
        // Save the current state
        const state = codeMirrorInstance.save();
        console.log('Saved state:', state);
      }
    });
  },
  beforeDestroy() {
    const codeMirrorInstance = this.$refs.codeMirrorEditor.codemirror;
    if (codeMirrorInstance) {
      codeMirrorInstance.off('update', this.onRenderEnd);
    }
  }
};
</script>

<style>
.render-code .render-code-container {
  border: 1px solid #3C3C3C;
}

.render-code .render-code-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3C3C3C;
}

.render-code .render-code-title {
  padding: 10px;
}

.render-code .render-code-icon {
  color: #E17833;
}

.render-code .render-code-buttons {
  display: flex;
  align-items: center;
}

.render-code .button {
  cursor: pointer;
  margin-left: 5px;
}

.render-code .button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.render-code .button:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.render-code .CodeMirror {
  height: auto;
}

.render-code .CodeMirror-scrollbar-filler,
.render-code .CodeMirror-gutter-filler {
  background-color: #2e2e2e !important;
}

.render-code .CodeMirror-vscrollbar,
.render-code .CodeMirror-hscrollbar {
  background-color: #2e2e2e;
}

.render-code .CodeMirror-vscrollbar::-webkit-scrollbar,
.render-code .CodeMirror-hscrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.render-code .CodeMirror-vscrollbar::-webkit-scrollbar-thumb,
.render-code .CodeMirror-hscrollbar::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 6px;
}

.render-code .CodeMirror-vscrollbar::-webkit-scrollbar-thumb:hover,
.render-code .CodeMirror-hscrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
</style>
<template>
  <RenderRuleGoJsonMessage v-if="value.type === 'rulegoJson'" :value="value" @apply="onApply" />
  <RenderMarkdownMessage v-else :value="value.content" @apply="onApply" />
</template>

<script>
import RenderRuleGoJsonMessage from './RenderRuleGoJsonMessage.vue';
import RenderMarkdownMessage from './RenderMarkdownMessage.vue';
export default {
  name: 'RenderAiChat',
  components: {
    RenderRuleGoJsonMessage,
    RenderMarkdownMessage,
  },
  emits: ['apply','rendered'],
  props: {
    value: {
      type: [Object],
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  methods: {
    onApply(value) {
      this.$emit('apply', value);
    },
  },
  mounted() {
    console.log(this.value)
  }
};
</script>

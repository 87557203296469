<template>
    <div class="rule-go-diagram">
        <div class="lf-container" ref="containerRef"></div>
    </div>
</template>

<script>
import LogicFlow from '@logicflow/core'
import LogicFlowNodeExtension from '@/components/LogicFlowNode'
import '@logicflow/core/es/index.css'
import '@logicflow/extension/lib/style/index.css'
import '@/components/LogicFlowNode/style.css'
import { mapRuleGoToLogicFlowData } from '@/utils/logicFlowRuleGo'

// 全局使用 每一个lf实例都具备 Control
// LogicFlow.use(Control)
LogicFlow.use(LogicFlowNodeExtension)

export default {
    name: 'RuleGoDiagram',
    emits: ['input'],
    props: {
        value: {
            type: String,
            default: '{}'
        }
    },
    watch: {
        value: {
            handler(value) {
                this.model.contentText = value
                if (this.value == '') return
                this.renderData(value)
            },
            immediate: true
        }
    },
    data() {
        return {
            model: {
                contentText: ''
            },
            containerRef: null,
            lf: null,
            loading: true
        }
    },
    mounted() {
        this.initializeLogicFlow()
        if (this.value == '') return
        this.renderData(this.value)
    },
    methods: {
        initializeLogicFlow() {
            if (!this.$refs.containerRef) return
            this.lf = new LogicFlow({
                container: this.$refs.containerRef,
                grid: {
                    size: 20,
                    visible: true,
                    type: "mesh",
                    config: {
                        color: "#2b2b2b",
                        thickness: 1
                    },
                },
                isSilentMode: true, // 设置为只读模式
                stopMoveGraph: true,
                background: {
                    backgroundColor: '#120f18'
                },
            })
        },
        renderData(data) {
            if (!this.lf) return
            if (typeof data === 'string') {
                try {
                    data = JSON.parse(data)
                }
                catch (e) {
                    console.error(e)
                    return
                }
            }

            let rule = { metadata: {}, nodes: [], endpoints: [] }
            Object.assign(rule, data)
            const flowData = mapRuleGoToLogicFlowData(rule)
            this.lf.render(flowData)
            this.lf.translateCenter()
        },
        translateCenter() {
            if (!this.lf) return
            this.lf.resize();
            this.lf.translateCenter();
        },
        updateValue(value) {
            this.$emit('input', value)
        }
    }
}
</script>
<style>
.rule-go-diagram {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.rule-go-diagram .lf-container {
    flex: 1 1 0;
    overflow: hidden;
}
</style>

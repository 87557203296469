<template>
    <div class="chat-input-box">
        <div class="chat-input-inner" :class="{ 'focus': isFocused }">
            <textarea v-model="val" class="input" ref="inputRef"
                :placeholder="placeholder"
                @keydown="onKeydown"
                @focus="onFocus"
                @blur="onBlur">
            </textarea>
            <div class="op-panel">
                <i title="send" class="el-icon-s-promotion" @click="onSend"></i>
                <!-- <i class="el-icon-arrow-down"></i> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['input', 'send'],
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请输入...'
        }
    },
    data() {
        return {
            val: this.value,
            isFocused: false // Track focus state
        }
    },
    watch: {
        value(val) {
            this.val = val
        },
        val(val) {
            this.updateValue(val)
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
        },
        onKeydown(event) {
            if (event.key == 'Enter') {
                event.preventDefault()
                this.onSend();
            }
        },
        onSend() {
            this.$emit('send', this.val)
            this.val = ''
        },
        onFocus() {
            this.isFocused = true;
        },
        onBlur() {
            this.isFocused = false;
        }
    },
    mounted() {
        this.$refs.inputRef.focus()
    }
}
</script>
<style>
.chat-input-box {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #3C3C3C;
    padding: 10px 10px;
}

.chat-input-box .chat-input-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 0;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    height: 100px;
    padding: 10px;
    border: 1px solid #3C3C3C;
    gap: 6px;
}

.chat-input-box .chat-input-inner.focus {
    border: 1px solid #0078d4;
}

.chat-input-box .chat-input-inner .input {
    flex: 1;
    display: block;
    background: transparent;
    border: none;
    color: white;
    caret-color: white;
    /** custom the textarea scroll bar to dark theme */
    scrollbar-color: #3C3C3C #636363;
}


.chat-input-box .chat-input-inner .input:focus {
    outline: none;
}

.chat-input-box .chat-input-inner .op-panel {
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    gap: 5px;
}

.chat-input-box .chat-input-inner .op-panel i {
    cursor: pointer;
}
</style>

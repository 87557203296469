<template>
    <div class="split-panel-group">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'SplitPanelGroup',
    emits: ['input', 'adjust-width'],
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            panelItems: [],
            currentWidth: 0,
            isMounted: false
        }
    },
    methods: {
        printChildren() {
            this.panelItems.forEach(child => {
                console.log(child.$slots.default[0].tag)
            })
        },
        updateValue(value) {
            this.emit('input', value)
        },
        registerChild(child) {
            const me = this;
            me.panelItems.push(child)
            child.$on('update:width', (width) => {
                me.adjustSiblingWidth(child, width)
            })
            if (me.isMounted) {
                me.averageChildren()
            }
        },
        unregisterChild(child) {
            const me = this;
            child.$off('update:width')
            const index = me.panelItems.indexOf(child)
            if (index > -1) {
                me.panelItems.splice(index, 1)
            }
            me.averageChildren()
        },
        averageChildren() {
            const me = this;
            const totalWidth = me.clientWidth
            const averageWidth = totalWidth / me.panelItems.length
            me.panelItems.forEach(child => {
                child.setWidth(averageWidth)
            })
        },
        adjustSiblingWidth(child, width) {
            const me = this;
            const index = me.panelItems.indexOf(child)
            const siblingIndex = index === 0 ? 1 : (index + 1);
            const sibling = me.panelItems[siblingIndex]
            let remainWidth = 0;
            for (let i = 0; i < me.panelItems.length; i++) {
                if (i !== index && i !== siblingIndex) {
                    remainWidth += me.panelItems[i].panelWidth
                }
            }
            const siblingWidth = me.clientWidth - remainWidth - width
            if (width >= child.minWidth && siblingWidth >= sibling.minWidth) {
                child.setWidth(width)
                sibling.setWidth(siblingWidth)
            }
            else {
                child.setWidth(me.clientWidth - sibling.panelWidth - remainWidth)
            }
            me.$emit('adjust-width', child)
        },
        replaceChildrenIndex(oldIndex, newIndex) {
            const me = this;
            const temp = me.panelItems[oldIndex];
            me.panelItems.splice(oldIndex, 1);
            me.panelItems.splice(newIndex, 0, temp);
        }
    },
    mounted() {
        const me = this;
        me.$nextTick(() => {
            me.clientWidth = me.$el.clientWidth;
            me.averageChildren();
        })
        me.isMounted = true;
    }
}
</script>
<style>
.split-panel-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}
</style>

<template>
  <div class="rule-go-code-editor">
    <div class="title">
      <span class="label">RuleGo Editor</span>
      <span class="buttons">
        <span class="json-tip">
          <span class="error" v-if="jsonError"><i class="el-icon-error"></i>{{ jsonError }}</span>
          <span class="correct" v-else><i class="el-icon-success"></i></span>
        </span>
        <button @click="onApply" class="button-apply">
          <i class="el-icon-upload"></i>Save
        </button>
        <ButtonClose @click="$emit('close')" />
      </span>
    </div>
    <div class="place-box">
      <codemirror :value="model.contentText" @input="onChange" :options="codeMirrorOptions" />
    </div>
  </div>
</template>

<script>
import ButtonClose from './ButtonClose.vue';
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css'; // 选择一个主题
import 'codemirror/mode/javascript/javascript.js'; // 导入 JSON 模式

export default {
  emits: ['apply', 'close', 'input'],
  components: {
    codemirror,
    ButtonClose
  },
  props: {
    value: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isMounted: false,
      jsonError: "",
      model: {
        contentText: ''
      },
      codeMirrorOptions: {
        mode: 'application/json',
        theme: 'material', // 选择一个主题
        lineNumbers: true, // 显示行号
        tabSize: 2, // 设置 Tab 大小
        indentWithTabs: true, // 使用空格进行缩进
        autoCloseBrackets: true, // 自动闭合括号
        matchBrackets: true, // 匹配括号
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"], // 显示行号和折叠
        foldGutter: true, // 启用代码折叠

      },
    }
  },
  watch: {
    value: {
      async handler(val) {
        this.model.contentText = val;
        this.validateJson();
      },
    },
  },
  methods: {
    onChange(value) {
      this.model.contentText = value;
      this.updateValue(value);
    },
    updateValue(value) {
      this.$emit('input', value)
    },
    validateJson() {
      if (this.model.contentText === '') {
        return;
      }
      try {
        JSON.parse(this.model.contentText);
        this.jsonError = '';
      } catch (e) {
        this.jsonError = e.message;
      }
    },
    onApply() {
      if (this.jsonError) return;
      this.$emit('apply', this.model.contentText);
    }
  },
  mounted() {
    this.model.contentText = this.value;
    this.validateJson();
  }
}
</script>
<style>
.rule-go-code-editor {
  display: flex;
  flex-direction: column;
}

.rule-go-code-editor>.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: white;
  cursor: move;
}

.rule-go-code-editor>.title>.label {
  flex: none;
}

.rule-go-code-editor>.title>.buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-left: 10px;
}

.rule-go-code-editor>.title>.buttons .button-apply {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #323232;
  height: 100%;
  color: #919196;
  padding: 5px 10px;
  background-color: transparent;
  gap: 5px;
  cursor: pointer;
}

.rule-go-code-editor>.title>.buttons .button-apply:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.rule-go-code-editor>.title>.buttons .button-apply:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.rule-go-code-editor .place-box {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.rule-go-code-editor .CodeMirror {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rule-go-code-editor .json-tip span,
.rule-go-code-editor .json-tip {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.rule-go-code-editor .json-tip .error {
  color: #ff0000;
}

.rule-go-code-editor .json-tip .correct {
  color: #00ff00;
}


.rule-go-code-editor .CodeMirror-scrollbar-filler,
.rule-go-code-editor .CodeMirror-gutter-filler {
  background-color: #2e2e2e !important;
}

.rule-go-code-editor .CodeMirror-vscrollbar,
.rule-go-code-editor .CodeMirror-hscrollbar {
  background-color: #2e2e2e;
}

.rule-go-code-editor .CodeMirror-vscrollbar::-webkit-scrollbar,
.rule-go-code-editor .CodeMirror-hscrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.rule-go-code-editor .CodeMirror-vscrollbar::-webkit-scrollbar-thumb,
.rule-go-code-editor .CodeMirror-hscrollbar::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 6px;
}

.rule-go-code-editor .CodeMirror-vscrollbar::-webkit-scrollbar-thumb:hover,
.rule-go-code-editor .CodeMirror-hscrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
</style>

export function mapRuleGoToLogicFlowData(ruleGo) {
  const { metadata } = ruleGo;
  const nodes = [];
  const edges = [];

  //默认初始节点
  const startNode = {
    id: "start",
    type: "rule-go-node",
    x:
      (ruleGo.ruleChain &&
        ruleGo.ruleChain.additionalInfo &&
        ruleGo.ruleChain.additionalInfo.layoutX) ||
      0,
    y:
      (ruleGo.ruleChain &&
        ruleGo.ruleChain.additionalInfo &&
        ruleGo.ruleChain.additionalInfo.layoutY) ||
      0,
    text: "Input",
    properties: {
      ruleGoType: "start",
    },
  };
  nodes.push(startNode);

  //用户拖入节点
  const metaNodes = (metadata.nodes || []).map((node) => {
    return {
      id: node.id,
      type: "rule-go-node", //node.type,
      x: (node.additionalInfo && node.additionalInfo.layoutX) || 0,
      y: (node.additionalInfo && node.additionalInfo.layoutY) || 0,
      text: node.name,
      properties: {
        ruleGoType: node.type,
      },
    };
  });
  nodes.push(...metaNodes);

  //触发节点(开始节点)
  const endpoints = [];
  for (const endpoint of metadata.endpoints || []) {
    endpoints.push({
      id: endpoint.id,
      type: "rule-go-node",
      x: (endpoint.additionalInfo && endpoint.additionalInfo.layoutX) || 0,
      y: (endpoint.additionalInfo && endpoint.additionalInfo.layoutY) || 0,
      text: endpoint.name,
      properties: {
        ruleGoType: endpoint.type,
      },
    });
  }
  nodes.push(...endpoints);

  let connections = metadata.connections || [];
  //BUG:AI会生成只有一个点的连线，这里过滤掉
  connections = connections.filter(
    (connection) => connection.fromId && connection.toId
  );
  //连线
  const metaEdges = connections.map((connection) => {
    return {
      type: "flow-link",
      text: connection.type,
      sourceNodeId: connection.fromId,
      targetNodeId: connection.toId,
    };
  });

  //默认输入节点到第一个节点的连线
  if (metaEdges[0]) {
    edges.push({
      type: "flow-link",
      sourceNodeId: "start",
      targetNodeId: metaNodes[0].id,
    });
  }
  //节点之间的连线
  edges.push(...metaEdges);

  //router连线
  const routerEdges = [];
  for (const endpoint of metadata.endpoints || []) {
    if (!endpoint.routers) {
      continue;
    }
    for (let router, index = 0; (router = endpoint.routers[index]); index++) {
      if (router.to.path === undefined) {
        //https://rulego.cc/pages/784fda/#%E8%AF%B4%E6%98%8E
        routerEdges.push({
          type: "flow-link",
          text: router.from ? router.from.path : "",
          sourceNodeId: endpoint.id,
          targetNodeId: metadata.nodes[index].id,
        });
      } else {

        let targetNodeId =(router.to && router.to.path
          ? router.to.path
          : ""
        ).split(":")[1];

        if (targetNodeId === undefined) {
          targetNodeId = metadata.nodes[index].id;
        }

        routerEdges.push({
          type: "flow-link",
          text: router.from ? router.from.path : "",
          sourceNodeId: endpoint.id,
          targetNodeId,
        });
      }
    }
  }
  edges.push(...routerEdges);

  return {
    nodes,
    edges,
  };
}

import { h } from '@logicflow/core'
import BaseNode from './BaseNode'

class RuleGoNode extends BaseNode.view {
  get iconUrl() {
    const name = this.props.model.properties?.ruleGoType ?? ''
    return `images/${name}.svg`
  }

  getIcon() {
    const { width, height } = this.props.model
    return h('image', {
      width: 30,
      height: 30,
      x: -width / 2,
      y: -height / 2,
      href: this.iconUrl
    })
  }
}

class RuleGoNodeModel extends BaseNode.model {
  initNodeData(data) {
    super.initNodeData(data)
    switch (data.properties.ruleGoType) {
      case 'exprFilter':
      case 'fieldFilter':
      case 'groupFilter':
      case 'jsFilter':
      case 'jsSwitch':
      case 'msgTypeSwitch':
      case 'x/luaFilter':
        this.defaultFill = 'rgb(241, 232, 97)'
        break
      case 'exprTransform':
      case 'jsTransform':
      case 'metadataTransform':
      case 'text/template':
      case 'x/luaTransform':
        this.defaultFill = 'rgb(121, 206, 241)'
        break
      case 'delay':
      case 'exec':
      case 'for':
      case 'functions':
      case 'groupAction':
      case 'iterator':
      case 'log':
        this.defaultFill = 'rgb(241, 146, 143)'
        break
      case 'dbClient':
      case 'mqttClient':
      case 'net':
      case 'restApiCall':
      case 'sendEmail':
      case 'ssh':
      case 'x/kafkaProducer':
      case 'x/natsClient':
      case 'x/opengeminiQuery':
      case 'x/opengeminiWrite':
      case 'x/rabbitmqClient':
      case 'x/redisClient':
        this.defaultFill = 'rgb(251, 199, 102)'
        break
      case 'flow':
        this.defaultFill = 'rgb(230, 224, 248)'
        break
      default:
        break
    }
  }
}

export default {
  type: 'rule-go-node',
  model: RuleGoNodeModel,
  view: RuleGoNode
}
